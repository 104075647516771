var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/**
 * @preserve
 * JS Implementation of incremental MurmurHash3 (r150) (as of May 10, 2013)
 *
 * @author <a href="mailto:jensyt@gmail.com">Jens Taylor</a>
 * @see http://github.com/homebrewing/brauhaus-diff
 * @author <a href="mailto:gary.court@gmail.com">Gary Court</a>
 * @see http://github.com/garycourt/murmurhash-js
 * @author <a href="mailto:aappleby@gmail.com">Austin Appleby</a>
 * @see http://sites.google.com/site/murmurhash/
 */
(function () {
  var cache; // Call this function without `new` to use the cached object (good for
  // single-threaded environments), or with `new` to create a new object.
  //
  // @param {string} key A UTF-16 or ASCII string
  // @param {number} seed An optional positive integer
  // @return {object} A MurmurHash3 object for incremental hashing

  function MurmurHash3(key, seed) {
    var m = (this || _global) instanceof MurmurHash3 ? this || _global : cache;
    m.reset(seed);

    if (typeof key === "string" && key.length > 0) {
      m.hash(key);
    }

    if (m !== (this || _global)) {
      return m;
    }
  }

  ; // Incrementally add a string to this hash
  //
  // @param {string} key A UTF-16 or ASCII string
  // @return {object} this

  MurmurHash3.prototype.hash = function (key) {
    var h1, k1, i, top, len;
    len = key.length;
    (this || _global).len += len;
    k1 = (this || _global).k1;
    i = 0;

    switch ((this || _global).rem) {
      case 0:
        k1 ^= len > i ? key.charCodeAt(i++) & 65535 : 0;

      case 1:
        k1 ^= len > i ? (key.charCodeAt(i++) & 65535) << 8 : 0;

      case 2:
        k1 ^= len > i ? (key.charCodeAt(i++) & 65535) << 16 : 0;

      case 3:
        k1 ^= len > i ? (key.charCodeAt(i) & 255) << 24 : 0;
        k1 ^= len > i ? (key.charCodeAt(i++) & 65280) >> 8 : 0;
    }

    (this || _global).rem = len + (this || _global).rem & 3; // & 3 is same as % 4

    len -= (this || _global).rem;

    if (len > 0) {
      h1 = (this || _global).h1;

      while (1) {
        k1 = k1 * 11601 + (k1 & 65535) * 3432906752 & 4294967295;
        k1 = k1 << 15 | k1 >>> 17;
        k1 = k1 * 13715 + (k1 & 65535) * 461832192 & 4294967295;
        h1 ^= k1;
        h1 = h1 << 13 | h1 >>> 19;
        h1 = h1 * 5 + 3864292196 & 4294967295;

        if (i >= len) {
          break;
        }

        k1 = key.charCodeAt(i++) & 65535 ^ (key.charCodeAt(i++) & 65535) << 8 ^ (key.charCodeAt(i++) & 65535) << 16;
        top = key.charCodeAt(i++);
        k1 ^= (top & 255) << 24 ^ (top & 65280) >> 8;
      }

      k1 = 0;

      switch ((this || _global).rem) {
        case 3:
          k1 ^= (key.charCodeAt(i + 2) & 65535) << 16;

        case 2:
          k1 ^= (key.charCodeAt(i + 1) & 65535) << 8;

        case 1:
          k1 ^= key.charCodeAt(i) & 65535;
      }

      (this || _global).h1 = h1;
    }

    (this || _global).k1 = k1;
    return this || _global;
  }; // Get the result of this hash
  //
  // @return {number} The 32-bit hash


  MurmurHash3.prototype.result = function () {
    var k1, h1;
    k1 = (this || _global).k1;
    h1 = (this || _global).h1;

    if (k1 > 0) {
      k1 = k1 * 11601 + (k1 & 65535) * 3432906752 & 4294967295;
      k1 = k1 << 15 | k1 >>> 17;
      k1 = k1 * 13715 + (k1 & 65535) * 461832192 & 4294967295;
      h1 ^= k1;
    }

    h1 ^= (this || _global).len;
    h1 ^= h1 >>> 16;
    h1 = h1 * 51819 + (h1 & 65535) * 2246770688 & 4294967295;
    h1 ^= h1 >>> 13;
    h1 = h1 * 44597 + (h1 & 65535) * 3266445312 & 4294967295;
    h1 ^= h1 >>> 16;
    return h1 >>> 0;
  }; // Reset the hash object for reuse
  //
  // @param {number} seed An optional positive integer


  MurmurHash3.prototype.reset = function (seed) {
    (this || _global).h1 = typeof seed === "number" ? seed : 0;
    (this || _global).rem = (this || _global).k1 = (this || _global).len = 0;
    return this || _global;
  }; // A cached object to use. This can be safely used if you're in a single-
  // threaded environment, otherwise you need to create new hashes to use.


  cache = new MurmurHash3();
  exports = MurmurHash3;
})();

export default exports;